import Connections from "./Connections";

const Hero: React.FC = () => (
  <section id='main'
    className="relative bg-cover bg-center bg-no-repeat py-8 bg-primary"
    style={{ backgroundImage: "url('/assets/img/bg-hero.jpg')" }}
  >
    <div className="absolute inset-0 z-20 bg-gradient-to-r from-hero-gradient-from to-hero-gradient-to"></div>
    <div className="container relative z-30 pt-20 pb-12 sm:pt-56 sm:pb-48 lg:pt-64 lg:pb-48">
      <div className="flex flex-col items-center justify-center lg:flex-row">
        <div className="rounded-full border-8 border-primary shadow-xl">
          <img src="/assets/pp.jpg" className="h-48 rounded-full sm:h-56" alt="Author" />
        </div>
        <div className="pt-8 sm:pt-10 lg:pl-8 lg:pt-0">
          <h1 className="text-center font-header text-4xl text-white sm:text-left sm:text-5xl md:text-6xl">
            Hello I'm Michael Ryan
          </h1>
          <div className="flex flex-col justify-center pt-3 sm:flex-row sm:pt-5 lg:justify-start">
            <div className="flex items-center justify-center sm:justify-start md:pl-1">
              <p className="font-body text-lg uppercase text-white">Let's connect</p>
              <div className="hidden sm:block">
                <i className="bx bx-chevron-right text-3xl text-yellow"></i>
              </div>
            </div>
            <div className="flex items-center justify-center pt-5 pl-2 sm:justify-start sm:pt-0 text-white">
              <Connections />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
