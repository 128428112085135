import React from 'react';
import Connections from "./Connections";

const Contact: React.FC = () => {
  return (
    <section id="contact" className="bg-gray-50 py-16 px-6 md:px-12 text-left">
      <div className="container mx-auto max-w-3xl">
        <h2 className="text-4xl font-extrabold text-gray-800 mb-6">
          Let’s Build Something Great Together
        </h2>
        <p className="text-lg text-gray-600 mb-8">
          If you're looking for a skilled software engineer with a passion for data, backend development, and system optimization, let’s connect! I’m always excited about new challenges and opportunities to innovate.
        </p>


        <div className="flex flex-col items-start gap-6">
          <div>
            <a
              href="https://docs.google.com/document/d/1V5bTGFC6-JdxDq9A9OhnOPZQsnkyxArkwb98gC6u1Nc/preview?format=pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white font-bold py-3 px-5 rounded-md hover:bg-blue-700 transition"
            >
              Download Resume
            </a>
          </div>

          <div>
            <p className="font-body text-lg font-semibold uppercase text-grey-20">
              Connect with me
            </p>
            <div className="flex items-center justify-start pt-5 pl-2 sm:justify-start sm:pt-0 text-primary">
              <Connections />
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default Contact;
