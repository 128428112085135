// firebaseConfig.ts

import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';

// Utility function to get environment variables safely
const getEnvVar = (key: string): string => {
  const value = process.env[key];
  if (!value) {
    console.error(`Missing environment variable: ${key}. Please check your .env file.`);
    throw new Error(`Missing environment variable: ${key}`);
  }
  return value;
};

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: getEnvVar('REACT_APP_FIREBASE_API_KEY'),
  authDomain: getEnvVar('REACT_APP_FIREBASE_AUTH_DOMAIN'),
  projectId: getEnvVar('REACT_APP_FIREBASE_PROJECT_ID'),
  storageBucket: getEnvVar('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getEnvVar('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
  appId: getEnvVar('REACT_APP_FIREBASE_APP_ID'),
  measurementId: getEnvVar('REACT_APP_FIREBASE_MEASUREMENT_ID'),
};

// Initialize Firebase
const app: FirebaseApp = initializeApp(firebaseConfig);

export { app };
export default firebaseConfig;
