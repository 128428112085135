import Connections from "./Connections";

const About: React.FC = () => (
  <section className="bg-grey-50 py-16 md:py-20" id="about">
    <div className="container mx-auto flex flex-col items-start lg:items-center"> {/* Changed flex-row to flex-col */}
      {/* Who am I Section */}
      <div className="w-full px-4 text-center lg:w-full lg:text-left mb-10"> {/* Added margin-bottom */}
        <h2 className="font-header text-4xl font-semibold uppercase text-primary sm:text-5xl lg:text-6xl">
          Who am I?
        </h2>
        <h4 className="pt-6 font-header text-xl font-medium text-black sm:text-2xl lg:text-3xl">
          I'm Michael Ryan, a Engineering Manager / Technical leads
        </h4>
        <p className="pt-6 font-body leading-relaxed text-grey-20">
          My journey started in Indonesia, and now I’m building my career in Aotearoa, New Zealand. With 9 years of experience in software engineering, my expertise spans Golang as my primary language, as well as Ruby, Python, SQL, and C#. I have 6 years of experience leading engineering teams of up to 10 cross-functional members, managing end-to-end project delivery, optimizing resource allocation, and spearheading complex re-architecture projects and database migrations. My background includes extensive work with Google Cloud Platform (GCP), BigQuery, Terraform, Docker, and Kubernetes, focusing on automation, infrastructure management, and cloud deployment. I’ve driven system optimization and efficiency through microservices architectures, APIs, and containerization. Additionally, I’ve led data engineering initiatives, managing data pipelines using DBT, Apache Kafka, and Apache Airflow. One key achievement was leading a re-architecture project to migrate a data warehouse from PostgreSQL to BigQuery, improving data quality from 80% to 92% while boosting system efficiency and reducing latency. I bring a hands-on leadership style, grounded in Agile/Scrum methodologies, with a focus on mentorship, team growth, and driving efficiency.
        </p>
        {/* Connections section */}
        <div className="flex flex-col justify-center pt-6 sm:flex-row lg:justify-start">
          <div className="flex items-center justify-center sm:justify-start">
            <p className="font-body text-lg font-semibold uppercase text-grey-20">
              Connect with me
            </p>
            <div className="hidden sm:block">
              <i className="bx bx-chevron-right text-2xl text-primary"></i>
            </div>
          </div>
          <div className="flex items-center justify-center pt-5 pl-2 sm:justify-start sm:pt-0 text-primary">
            <Connections />
          </div>
        </div>
      </div>

      {/* Skills Section */}
      <h3
        className="pt-6 text-center font-header text-xl font-medium text-primary sm:text-2xl lg:text-3xl"
      >
        Related Skills
      </h3>
      <div className="w-full px-4 pl-0 pt-10 lg:w-full lg:pl-12 lg:pt-0">
        {/* Programming Skills */}
        <h3 className="font-body font-semibold text-black uppercase mt-8 mb-4">Programming Skills</h3>
        <div className="grid grid-cols-2 gap-4">
          {[
            { skill: 'Golang', description: 'Primary language, backend development, automation' },
            { skill: 'Ruby', description: '6 years of experience in web applications and system design' },
            { skill: 'Python', description: 'Data processing, scripting, and automation' },
            { skill: 'SQL', description: 'Database querying, optimization, MySQL, PostgreSQL' },
            { skill: 'C#', description: 'Experience with system automation' },
          ].map((item, index) => (
            <div key={index} className={`pt-4 pb-4 pl-4 pr-4 rounded-lg bg-gray-200}`}>
              <div className="flex items-end justify-between">
                <h4 className="font-body font-semibold uppercase text-primary">{item.skill}</h4>
              </div>
              <div className="text-gray-600">{item.description}</div>
            </div>
          ))}
        </div>

        {/* Technology Skills */}
        <h3 className="font-body font-semibold text-black uppercase mb-4">Technology Skills</h3>
        <div className="grid grid-cols-2 gap-4">
          {[
            { skill: 'Google Cloud Platform (GCP)', description: 'Cloud operations, BigQuery, and data management' },
            { skill: 'Kubernetes / Docker', description: 'Containerization and orchestration of services' },
            { skill: 'CI/CD', description: 'Continuous integration and delivery pipelines' },
            { skill: 'Terraform', description: 'Infrastructure as code, automation of deployments' },
            { skill: 'Elasticsearch', description: 'Search and analytics engine for large datasets' },
            { skill: 'Redis', description: 'In-memory data structure store for caching' },
            { skill: 'Microservices', description: 'Developing scalable, distributed systems' },
            { skill: 'APIs', description: 'Creating and managing APIs for services' },
            { skill: 'MySQL / PostgreSQL / SQL Server', description: 'Database management and optimization' },
          ].map((item, index) => (
            <div key={index} className={`pt-4 pb-4 pl-4 pr-4 rounded-lg bg-gray-200}`}>
              <div className="flex items-end justify-between">
                <h4 className="font-body font-semibold uppercase text-primary">{item.skill}</h4>
              </div>
              <div className="text-gray-600">{item.description}</div>
            </div>
          ))}
        </div>

        {/* Data Engineering Skills */}
        <h3 className="font-body font-semibold text-black uppercase mb-4">Data Engineering Skills</h3>
        <div className="grid grid-cols-2 gap-4">
          {[
            { skill: 'BigQuery', description: 'Managing and processing large datasets for analytics' },
            { skill: 'Apache Kafka', description: 'Stream processing and real-time data pipelines' },
            { skill: 'DBT', description: 'Data transformation and modeling' },
            { skill: 'Apache Airflow', description: 'Workflow orchestration for data pipelines' },
            { skill: 'ETL', description: 'Extract, transform, load operations for data systems' },
          ].map((item, index) => (
            <div key={index} className={`pt-4 pb-4 pl-4 pr-4 rounded-lg bg-gray-200}`}>
              <div className="flex items-end justify-between">
                <h4 className="font-body font-semibold uppercase text-primary">{item.skill}</h4>
              </div>
              <div className="text-gray-600">{item.description}</div>
            </div>
          ))}
        </div>

        {/* Tooling Skills */}
        <h3 className="font-body font-semibold text-black uppercase mb-4">Tooling Skills</h3>
        <div className="grid grid-cols-2 gap-4">
          {[
            { skill: 'GitHub / GitLab', description: 'Version control and CI/CD pipelines' },
            { skill: 'Jira', description: 'Project management and Agile workflows' },
            { skill: 'Confluence', description: 'Documentation and collaboration platform' },
          ].map((item, index) => (
            <div key={index} className={`pt-4 pb-4 pl-4 pr-4 rounded-lg bg-gray-200}`}>
              <div className="flex items-end justify-between">
                <h4 className="font-body font-semibold uppercase text-primary">{item.skill}</h4>
              </div>
              <div className="text-gray-600">{item.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default About;
