const Experience: React.FC = () => (
  <section className="container py-16 md:py-20" id="experience">
    <h2 className="text-center font-header text-4xl font-semibold uppercase text-primary sm:text-5xl lg:text-6xl">
      My work experience
    </h2>

    <div className="relative mx-auto mt-12 flex w-full flex-col lg:w-2/3">
      <span className="left-2/5 absolute inset-y-0 ml-10 hidden w-0.5 bg-grey-40 md:block"></span>
      {[
        {
          company: 'Govtech Edu Indonesia',
          duration: 'Oct 2022 - Present',
          role: 'Engineering Manager / Tech Lead (Hands-On)',
          description:
            `I led a team of 6 data and backend engineers to build a maintainable data pipeline, ensuring seamless access across platforms within an organization of over 500 employees. The tech stack included GitLab pipelines, Elasticsearch, Terraform, GCP, BigQuery, Apache Kafka, DBT, Apache Airflow, PostgreSQL, MySQL, Golang, Python, and .NET, processing hundreds of terabytes of data. I spearheaded a re-architecture effort to transition from PostgreSQL to BigQuery, implemented a message broker that improved efficiency and data quality from 80% to 92%, and led the migration of 500+ databases into a unified system at GovTech Edu, streamlining over 120 annually created databases. I developed admin services in a Self-serve Data Quality Management system integrated with BigQuery, created a new team culture that reduced sprint cycles from 2 weeks to 1 week, and actively participated in all software development life cycle phases, including leading and managing Agile meetings from sprint planning to reviews.`,
        },
        {
          company: 'Bukalapak.com',
          duration: 'Apr 2021 - Oct 2022',
          role: 'Engineering Manager (Hands-On)',
          description:
            'I led a team of 8 cross-functional engineers responsible for managing the entire marketplace seller platform, handling 100-300 requests per second (RPS) with a tech stack that included GitLab pipelines, Elasticsearch, Terraform, GCP, Ruby, and Golang. With over 500 engineers managing 100+ repositories, daily deployments were made for monolith services, ensuring high availability and performance. I successfully trained and led small teams in managing production issues and marketplace development, resulting in efficient issue resolution by a two-person IT support team with no incidents during three quarters of leadership. I optimized resource usage, maintaining CPU and memory utilization within 40-60%, improving system performance by 20%, and led the ClickHouse project, reducing MySQL query times from constant timeouts to under 1 second. I also fostered a collaborative team culture with non-forced after-work activities and humor in meetings, improving team morale and productivity. While managing a larger project scope of 10+ microservices and 1 monolith, I ensured team performance remained high and successfully met project goals. Additionally, I was involved in all phases of the software development life cycle and led all Agile meetings, including sprint planning, scoping, and reviews.',
        },
        {
          company: 'Bukalapak.com',
          duration: 'July 2019 - Apr 2021',
          role: 'Technical Lead (Hands-On)',
          description:
            "I led a squad of 10 cross-functional engineers responsible for maintaining and developing Bukalapak's revenue system. As a tech interviewer, I conducted technical evaluations for engineering roles, resulting in successful hires that aligned with the team's standards and contributed to project success. I led a task force of 6 senior engineers in transitioning Bukalapak's seller membership from subscription-based to fee-based, completing the project within one week without any issues affecting seller features. I mentored 3 junior engineers, providing guidance on technical skills, code reviews, and best practices, which helped them progress to medior level within a year and significantly improve their contributions. Additionally, I led the Facebook External Ads Discrepancies project, reducing data mismatches between Facebook and internal records for 400,000 promoted products from 70% to 20%, which increased revenue by 15-18%.",
        },
        {
          company: 'Bukalapak.com',
          duration: 'Mar 2017 - July 2019',
          role: 'Software Engineer',
          description:
            "Revenue team at Bukalapak, I focus on developing and maintaining services that enhance the sellers' experience by creating scalable and efficient services that provide statistical visualizations. I have continuously improved my backend development skills while optimizing existing systems. I achieved a 99% SLO by implementing effective monitoring, alerting, and testing procedures, ensuring system reliability and surpassing service availability targets. By streamlining processes and implementing automation, I worked at a faster pace than other engineers, leading to quicker project completion and improved team efficiency. Additionally, I created 3-5 microservices to replace a monolithic service, resulting in faster deployments, improved scalability, and better fault isolation.",
        },
        {
          company: 'Liberty Jaya',
          duration: 'Apr 2015 - Mar 2017',
          role: 'Software Developer',
          description:
            'Developed and maintained internal tools to support business operations, improving workflow efficiency and automation.',
        },
      ].map((job, index) => (
        <div className="mt-8 flex flex-col text-center md:flex-row md:text-left" key={index}>
          <div className="md:w-2/5">
            <div className="flex justify-center md:justify-start">
              <span className="shrink-0">
                {job.company}
              </span>
              <div className="relative ml-3 hidden w-full md:block">
                <span className="absolute inset-x-0 top-1/2 h-0.5 -translate-y-1/2 transform bg-grey-70"></span>
              </div>
            </div>
          </div>
          <div className="md:w-3/5">
            <div className="relative flex md:pl-18">
              <span className="absolute left-8 top-1 hidden h-4 w-4 rounded-full border-2 border-grey-40 bg-white md:block"></span>
              <div className="mt-1 flex">
                <i className="bx bxs-right-arrow hidden text-primary md:block"></i>
                <div className="md:-mt-1 md:pl-8">
                  <span className="block font-body font-bold text-grey-40">{job.duration}</span>
                  <span className="block pt-2 font-header text-xl font-bold uppercase text-primary">{job.role}</span>
                  <div className="pt-2">
                    <span className="block font-body text-black justify-center">{job.description}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
  </section>
);

export default Experience;
