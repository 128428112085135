import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Exp from './components/Experience';
import Contact from './components/Contact';
import './assets/styles/main.css';
import { app } from './firebaseConfig';
import { getAnalytics, isSupported, logEvent, Analytics } from 'firebase/analytics';

const App: React.FC = () => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  // Initialize Analytics when component mounts
  useEffect(() => {
    const initializeAnalytics = async () => {
      try {
        const supported = await isSupported();
        if (supported) {
          const analyticsInstance = getAnalytics(app);
          setAnalytics(analyticsInstance); // Set analytics instance in state
        }
      } catch (error) {
        console.error('Error initializing Firebase Analytics:', error);
      }
    };

    initializeAnalytics();
  }, []);

  // Log page view when analytics is ready
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_path: window.location.pathname, // Captures the current page path
        page_title: document.title,          // Captures the document title
        page_location: window.location.href  // Captures the full URL
      });
    }
  }, [analytics]); // Only run when analytics is set

  return (
    <div>
      <Header />
      <Hero />
      <About />
      <Exp />
      <Contact />
    </div>
  );
};

export default App;
